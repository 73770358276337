<template>
  <v-app>
    <v-app-bar app color="white" elevate-on-scroll class="no-print">
      <div class="d-flex align-center">
        <a href="https://balibotanica.com/">
          <v-img class="shrink mr-2" max-height="75px" max-width="75px" src="./assets/logo.png"></v-img>
        </a>
      </div>

      <v-spacer></v-spacer>
      
      <v-menu rounded="rounded" offset-y>
        <template v-slot:activator="{ attrs, on }">
          <v-btn text color="accent" class="hidden-sc" v-bind="attrs" v-on="on">
            treatments
          </v-btn>
        </template>

        <v-list>
          <v-list-item v-for="treatment in treatments" :key="treatment" :href="treatment.link">
            <v-list-item-title class="submenu" v-text="treatment.name"></v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
      <v-btn href="https://balibotanica.com/packages/" target="_blank" text color="accent" class="hidden-sc">
        <span>packages</span>
      </v-btn>
      <v-btn href="https://balibotanica.com/ayurvedic-programs/" target="_blank" text color="accent" class="hidden-sc">
        <span>3-day programs</span>
      </v-btn>
      <v-menu rounded="rounded" offset-y>
        <template v-slot:activator="{ attrs, on }">
          <v-btn text color="accent" class="hidden-sc" v-bind="attrs" v-on="on">
            ayurveda
          </v-btn>
        </template>

        <v-list>
          <v-list-item v-for="ayurveda in ayurvedas" :key="ayurveda" :href="ayurveda.link">
            <v-list-item-title class="submenu" v-text="ayurveda.name"></v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
      <v-btn href="https://balibotanica.com/gift-voucher/" target="_blank" text color="accent" class="hidden-sc">
        <span>gift voucher</span>
      </v-btn>

      <v-menu rounded="rounded" offset-y>
        <template v-slot:activator="{ attrs, on }">
          <v-btn text color="accent" class="hidden-sc" v-bind="attrs" v-on="on">
            about
          </v-btn>
        </template>

        <v-list>
          <v-list-item v-for="about in abouts" :key="about" :href="about.link">
            <v-list-item-title class="submenu" v-text="about.name"></v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
      <v-btn href="https://balibotanica.com/contact-us/" target="_blank" text color="accent" class="hidden-sc">
        <span>contact</span>
      </v-btn>
      <v-app-bar-nav-icon class="hidden-mb" @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
    </v-app-bar>

    <v-navigation-drawer v-model="drawer" absolute temporary>
      <v-list nav dense>
        <v-list-item-group>
          <v-list-item v-for="link in links" :href="link.link" :key="link.name">
            <v-list-item-title class="submenu" v-text="link.name"></v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>

    <v-main>
      <router-view></router-view>
    </v-main>

    <v-footer color="footer" class="no-print">
      <v-container>
        <v-row>
          <v-col cols="12" sm="3">
            <h2>Bali Botanica</h2>
            <p>With 16 years of customer satisfaction, Botanica is your wellness destination in Ubud for authentic
              Ayurvedic
              treatments.</p>
            <v-btn href="https://balibotanica.com/spa-treatments/" target="_blank" text color="accent"
              class="hidden-sc">
              <span>BOOK ONLINE</span>
            </v-btn>
          </v-col>
          <v-col cols="12" sm="3">
            <h2>Important Links</h2>
            <ul>
              <li>
                <a href="https://balibotanica.com/spa-treatments/">Spa Treatments</a>
              </li>
              <li>
                <a href="https://balibotanica.com/spa-packages/">Spa Packages</a>
              </li>
              <li>
                <a href="https://balibotanica.com/special-offers/">Special Offers</a>
              </li>
              <li>
                <a href="https://balibotanica.com/about-us/">About Us</a>
              </li>
              <li>
                <a href="https://balibotanica.com/gift-voucher/">Gift Voucher</a>
              </li>
            </ul>
          </v-col>
          <v-col cols="12" sm="3">
            <h2>Contact Us</h2>
            <p><a href="https://g.page/BaliBotanica/">Jl. Sanggingan, Ubud</a> <br />
              Phone: +62361 976 739 <br />
              Tel./WhatsApp: <a href="https://wa.link/lscehs">+62 821 4717 5150</a> <br />
              Email: <a href="mailto:info@balibotanica.com">info@balibotanica.com</a>
            </p>

          </v-col>
          <v-col cols="12" sm="3">
            <h2>Opening Hours</h2>
            <p>Daily: 09:00 – 21:00</p>

          </v-col>
        </v-row>
        <v-row style="padding:56px 0 24px;">
          <v-col cols="12" sm="4">
          </v-col>
          <v-col cols="12" sm="4">
            <p class="mb-center"><a href="https://www.oneworldayurveda.com/privacy-policy/">Privacy policy</a> | © {{
          new
            Date().getFullYear() }} Bali Botanica, All Rights Reserved.</p>
          </v-col>
          <v-col style="text-align:end;" class="mb-center" cols="12" sm="4">

          </v-col>
        </v-row>
      </v-container>
    </v-footer>
  </v-app>
</template>

<script>
export default {
  name: 'App',
  data() {
    return {
      drawer: false,
      group: null,
      treatments: [
        {
          'name': 'AYURVEDIC TREATMENTS',
          'link': 'https://balibotanica.com/ayurvedic-treatments/'
        },
        {
          'name': 'TRADISIONAL TREATMENTS',
          'link': 'https://balibotanica.com/traditional-treatments/'
        },
      ],
      ayurvedas: [
        {
          'name': 'ABOUT AYURVEDA',
          'link': 'https://balibotanica.com/about-ayurveda/'
        },
        {
          'name': 'FREE DOSHA QUIZ',
          'link': 'https://book.balibotanica.com/free-dosha-quiz'
        },
      ],
      abouts: [
        {
          'name': 'ABOUT US',
          'link': 'https://balibotanica.com/about-us/'
        },
        {
          'name': 'A WORD FROM FOUNDER',
          'link': 'https://balibotanica.com/a-word-from-the-founder/'
        },
        {
          'name': 'FAQ',
          'link': 'https://balibotanica.com/faq/'
        },
        {
          'name': 'TESTIMONIAL',
          'link': 'https://balibotanica.com/testimonials/'
        },
        {
          'name': 'DISCLAIMER',
          'link': 'https://balibotanica.com/program-participants-disclaimer/'
        },
        {
          'name': 'PRIVACY POLICY',
          'link': 'https://balibotanica.com/privacy-policy/'
        }
      ],
      links: [
        {
          'link': 'https://balibotanica.com/treatments/',
          'name': 'Treatments',
        },
        {
          'link': 'https://balibotanica.com/packages/',
          'name': 'Packages',
        },
        {
          'link': 'https://balibotanica.com/ayurvedic-programs/',
          'name': '3-Days Programs',
        },
        {
          'link': 'https://balibotanica.com/about-ayurveda/',
          'name': 'Ayurveda',
        },
        {
          'link': 'https://balibotanica.com/gift-voucher/',
          'name': 'Gift Voucher',
        },
        {
          'link': 'https://balibotanica.com/about-us/',
          'name': 'About',
        },
        {
          'link': 'https://balibotanica.com/contact-us/',
          'name': 'Contact',
        },
      ]
    }
  },
  watch: {
    group() {
      this.drawer = false
    },
  },
};
</script>

<style>
a {
  text-decoration: none;
  color: #959D4F !important;
}

.sticky-top {
  position: sticky;
  top: 60px;
}

iframe {
  height: 100%;
  width: 100%;
}

.bgReser {
  background-color: #d0dfd4 !important;
}

.hidden-mb {
  display: none !important;
}

.submenu {
  font-size: 0.8rem !important;
  color: #000 !important;
}

.text-hidden.v-text-field--enclosed .v-text-field__details {
  display: none !important;
}

@import url('https://fonts.googleapis.com/css2?family=Work+Sans:wght@500&display=swap');

.v-picker {
  width: 100% !important;
}

header a,
header button {
  font-family: 'Work Sans', sans-serif !important;
  font-size: 13px !important;
}

.v-toolbar__content a {
  font-family: 'Work Sans', sans-serif !important;
}

.v-application {
  font-family: 'Nunito Sans', sans-serif !important;
  color: #555555 !important;
}

h1 {
  font-weight: 200;
  line-height: 1.18em !important;
  color: #617262 !important;
  font-size: 34px;
  margin-bottom: 25px;
}

.text-span {
  font-size: 12px;
  font-weight: 400;
  color: #777771;
}

h6 {
  font-weight: 500;
  font-family: 'Nunito Sans';
  font-size: 17px;
  letter-spacing: 0.08em;
  text-transform: uppercase;
  margin-top: 24px;
  color: #617262 !important;
  margin-bottom: 8px;
}

h2 {
  font-weight: 200;
  line-height: 1.18em !important;
  color: #617262 !important;
  font-size: 30px;
  /* margin-bottom: 25px; */
}

footer h2 {
  font-weight: 200;
  line-height: 1.18em !important;
  color: #405861 !important;
  font-size: 20px;
}

.h2-mb-10 {
  margin-bottom: 16px !important;
}

h4 {
  line-height: 1.18em !important;
  font-family: 'Nunito Sans' !important;
  letter-spacing: -.01em;
  font-size: 25px;
  line-height: 1.05714em;
  font-weight: 500;
  margin: 25px 0px;
  color: #677869 !important;
}

p {
  /* font-weight: 300; */
  color: #555555;
  font-size: 17px;
  line-height: 1.6;
  font-family: 'Open Sans' !important;
}

/* font end */

.v-text-field--outlined fieldset {
  border: 0 !important;
  background-color: #EDEDED !important;
  border-radius: 0 !important;
}

.v-btn {
  font-family: 'Work Sans', sans-serif !important;
}

@media only screen and (max-width: 600px) {
  .sticky-top {
    position: relative;
    top: 0;
  }

  iframe {
    height: 600px;
  }

  .hidden-mb {
    display: block !important;
  }

  .hidden-sc {
    display: none !important;
    ;
  }
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.border-information {
  border: #768c7a 1px solid;
  padding: 20px 20px 20px 25px;
}

li,
.para {
  /* font-weight: 300; */
  color: #555555;
  font-size: 17px;
  line-height: 1.6;
  font-family: 'Nunito Sans' !important;
}

.para {
  margin-top: 40px;
}

.border-information ul li {
  padding-left: 4px;
}

.v-footer {
  padding: 40px 0px !important;
}

footer p {
  margin-top: 14px;
  color: #405861 !important;
  font-size: 14px;
  line-height: 1.7em;
}

footer a {
  color: #405861 !important;
  text-decoration: none;
  font-family: 'Nunito Sans' !important;
  font-size: 14px;
}

hr {
  border: #536354 1px solid !important;
}

.footer-mt {
  margin-top: 20px;
}

.footer-mb {
  margin-bottom: 0px;
}

@media only screen and (max-width: 600px) {
  .mb-center {
    margin-left: auto;
    margin-right: auto;
    text-align: center !important;
    ;
  }
}

#mc_embed_signup form {
  margin: 0 !important;
}

#mc_embed_signup .mc-field-group {
  width: 100% !important;
}

#mc_embed_signup .button {
  width: 100% !important;
  height: auto !important;
  padding: 4px !important;
  border-radius: 0px !important;
  background: linear-gradient(to right, #405861 50%, #768C7A 50%);
  background-size: 200% 100%;
  background-position: right bottom;
  transition: all .2s ease-out;
}

#mce-EMAIL {
  background-color: #fff;
  border-radius: 0 !important;
}

ul {
  padding: 0 !important;
}

li {
  list-style: none;
  padding: 5px 0px;
}
</style>