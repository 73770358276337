import DoshaQuiz from './components/DoshaQuiz/QuizForm.vue'
import Result from './components/DoshaQuiz/ResultQuiz.vue'
import FreeDoshaQuiz from './components/DoshaQuizFree/QuizForm.vue'
import FreeResult from './components/DoshaQuizFree/ResultQuiz.vue'
import FormOnline from './components/FormOnline/MainForm.vue'

export default [
    {
        path: '/dosha-quiz', component: DoshaQuiz
    },
    {
        path: '/result-quiz/:id', component: Result
    },
    {
        path: '/free-dosha-quiz', component: FreeDoshaQuiz
    },
    {
        path: '/free-result-quiz/:id', component: FreeResult
    },
    {
        path: '/form-ayurvedic-programs', component: FormOnline
    },
]